import axios from "axios";
import { ATSSStorage, ATSSStorageKeys } from "./atss_storage";

const baseURLS = {
    dev: 'http://127.0.0.1:8000/api/',
    devATSS: 'https://atssapi.chooseathena.com/api/',
    // prodATSS: 'https://atssapi.chooseathena.com/api/',
    prodIntegration: 'https://findplus.w-locate.com:8443/integration/',
    prodStaging: 'https://findplus.w-locate.com:8443/staging/find.api/',
    prodATSS: 'https://api.atss.w-locate.com/api/',
}

export const axiosIntegrationInstance = axios.create({
    baseURL: baseURLS.prodIntegration
});

axiosIntegrationInstance.interceptors.request.use(config => {
    const storage = new ATSSStorage();
    const account = storage.getItem(ATSSStorageKeys.account);
    if (account) {
        const token = account.ApiKey;
        config.headers.Token = token;
    }
    return config;
});

export const axiosATSSInstance = axios.create({
    baseURL: baseURLS.devATSS
});

axiosATSSInstance.interceptors.request.use(config => {
    const storage = new ATSSStorage();
    const account = storage.getItem(ATSSStorageKeys.account);
    if (account) {
        const token = account.ApiKey;
        config.headers.Key = token;
    }
    return config;
});